import React, { Component } from 'react';
import Die from './Die';
import { v4 as uuidv4 } from 'uuid';
import Questions from './Questions';

import './Dice.css';

class Dice extends Component {
  state = {
    Dice: [],
    renderQues: false,
  };

  handleClick = () => {
    this.setState({ renderQues: true });
  };

  generateDice = (difficulty) => {
    let tempArr = [];
    for (let i = 0; i < this.props.numDice; i++) {
      let randomNum = Math.floor(Math.random() * 100);
      let randomDie = this.generateDie(randomNum, difficulty, this.props.turn);
      tempArr.push(randomDie);
    }
    this.props.addRoll(tempArr);
    this.setState({ Dice: tempArr, renderQues: false });
  };

  generateDie = (randomNum, level, turn) => {
    //0:Strike, 1: Spare, 2: Blank, 3: Pin

    //Is this the player's first turn?
    if (turn === 1) {
      switch (level) {
        case 'easy':
          if (randomNum < 25) return 0;
          else if (randomNum < 40) return 1;
          else if (randomNum < 80) return 2;
          else if (randomNum < 100) return 3;
          break;
        case 'medium':
          if (randomNum < 10) return 0;
          else if (randomNum < 30) return 1;
          else if (randomNum < 70) return 2;
          else if (randomNum < 100) return 3;
          break;
        case 'hard':
          if (randomNum < 2) return 0;
          else if (randomNum < 20) return 1;
          else if (randomNum < 60) return 2;
          else if (randomNum < 100) return 3;
          break;
        default:
          throw Error('BowlingDice.js Error: Case not specified');
      }
    }

    //Is this the player's second turn?
    if (turn === 2) {
      switch (level) {
        case 'easy':
          if (randomNum < 30) return 0;
          else if (randomNum < 60) return 1;
          else if (randomNum < 90) return 2;
          else if (randomNum < 100) return 3;
          break;
        case 'medium':
          if (randomNum < 20) return 0;
          else if (randomNum < 40) return 1;
          else if (randomNum < 70) return 2;
          else if (randomNum < 100) return 3;
          break;
        case 'hard':
          if (randomNum < 20) return 0;
          else if (randomNum < 22) return 1;
          else if (randomNum < 42) return 2;
          else if (randomNum < 100) return 3;
          break;
        default:
          throw Error('BowlingDice.js Error: Case not specified');
      }
    }
  };

  render() {
    const { Dice, renderQues } = this.state;
    let renderDice;
    if (Dice.length) {
      renderDice = Dice.map((die) => {
        return <Die key={uuidv4()} val={die} />;
      });
    }

    //Are we currently rendering the questions?
    if (renderQues) {
      return <Questions generateDice={this.generateDice} />;
    } else {
      return (
        <div className="Dice">
          {Dice.length ? <h3>Previous Roll:</h3> : null}
          <div className="Dice-List">{renderDice}</div>
          {Dice.length ? <hr></hr> : null}
          <h1>It is Player {this.props.currentPlayer}'s turn.</h1>
          <button className="button" onClick={this.handleClick}>
            {this.props.currentFrame === 10 ? 'Final Turn' : 'Roll'}
          </button>
        </div>
      );
    }
  }
}

export default Dice;
