import React, { Component } from 'react';
import './DisplayScore.css';
import { v4 as uuidv4 } from 'uuid';

class DisplayScore extends Component {
  render() {
    let { players, currentPlayer, currentFrame } = this.props;

    let renderScoreboard = players.map((player) => (
      <tr key={uuidv4()}>
        <td className={currentPlayer === player.id ? 'Highlighted' : null}>
          {player.id}
        </td>
        {player.scores.map((score) => (
          <td key={uuidv4()}>
            <div className="score-box">
              <div
                className={
                  score.FrameScore[1] &&
                  score.frame === currentFrame &&
                  player.id === currentPlayer
                    ? 'left-box Highlighted'
                    : 'left-box'
                }
              >
                {score.FrameScore[1]
                  ? score.FrameScore[1].type === '/'
                    ? '/'
                    : score.FrameScore[1].score
                  : null}
              </div>
              <div
                className={
                  score.FrameScore[0] &&
                  !score.FrameScore[1] &&
                  score.frame === currentFrame &&
                  player.id === currentPlayer
                    ? 'right-box Highlighted'
                    : 'right-box'
                }
              >
                {score.FrameScore[0]
                  ? score.FrameScore[0].type === 'X'
                    ? 'X'
                    : score.FrameScore[0].score
                  : null}
              </div>
              {score.frame === 10 && score.FrameScore[2] ? (
                <div className="right-box">{score.FrameScore[2].score}</div>
              ) : null}
            </div>
            <div className="bottom-box">{score.finalScore}</div>
          </td>
        ))}
        {player.scores[9] ? (
          player.scores[9].isCompleted ? (
            <td className="FinalScore">{player.scores[9].finalScore}</td>
          ) : null
        ) : null}
      </tr>
    ));
    return (
      <div className="Scoreboard">
        <table>
          <thead>
            <tr>
              <th>Player</th>
              <th className={currentFrame === 1 ? 'Highlighted' : null}>1</th>
              <th className={currentFrame === 2 ? 'Highlighted' : null}>2</th>
              <th className={currentFrame === 3 ? 'Highlighted' : null}>3</th>
              <th className={currentFrame === 4 ? 'Highlighted' : null}>4</th>
              <th className={currentFrame === 5 ? 'Highlighted' : null}>5</th>
              <th className={currentFrame === 6 ? 'Highlighted' : null}>6</th>
              <th className={currentFrame === 7 ? 'Highlighted' : null}>7</th>
              <th className={currentFrame === 8 ? 'Highlighted' : null}>8</th>
              <th className={currentFrame === 9 ? 'Highlighted' : null}>9</th>
              <th className={currentFrame === 10 ? 'Highlighted' : null}>10</th>
              <th>Final Score</th>
            </tr>
          </thead>

          <tbody>{renderScoreboard}</tbody>
        </table>
      </div>
    );
  }
}

export default DisplayScore;
