import React, { Component } from 'react';

import './Questions.css';

class Questions extends Component {
  state = {
    question: {
      operand1: null,
      operand2: null,
      operator: null,
    },
    answer: '',
    isCorrect: false,
    numCorrect: 0,
    questionNum: 1,
    timeLeft: this.props.timeLeftInSeconds,
  };

  componentDidMount = () => {
    this.mounted = true;
    this.generateQuestions();
    this.answerInput.focus();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  componentDidUpdate = () => {
    if (this.answerInput) {
      this.answerInput.focus();
    }
  };

  returnAnswer = () => {
    if (this.mounted)
      switch (this.state.numCorrect) {
        case 0: //Hard -- All questions are wrong.
          this.props.generateDice('hard');
          break;
        case 1: //Medium -- One question right.
          this.props.generateDice('medium');
          break;
        case 3: //Easy -- All questions are correct.
          this.props.generateDice('easy');
          break;
        default:
          break;
      }
  };

  generateQuestions = () => {
    if (this.state.questionNum === 2) {
      this.returnAnswer();
    }

    //Set the timer to 4 seconds.
    let question = {
      operand1: this.generateOperand(),
      operand2: this.generateOperand(),
      operator: this.generateOperator(),
    };

    let intervalTimer = setInterval(() => {
      if (this.mounted)
        this.setState(
          (st) => ({
            timeLeft: st.timeLeft - 1,
          }),
          () => {
            if (this.state.timeLeft === 0) clearInterval(intervalTimer);
          }
        );
    }, 1000);

    if (this.mounted)
      this.setState({
        question: question,
        timeLeft: this.props.timeLeftInSeconds,
        intervalTimer: intervalTimer,
      });
  };

  generateOperand = () => {
    return Math.floor(Math.random() * 10) + 1;
  };

  generateOperator = () => {
    let randNum = Math.floor(Math.random() * 2);
    switch (randNum) {
      case 0:
        return '+';
      case 1:
        return '-';
      default:
        break;
    }
  };

  handleChange = (e) => {
    if (this.mounted)
      this.setState({ answer: e.target.value }, () => {
        this.checkAnswer();
      });
  };

  checkAnswer = () => {
    let ans;
    let { operand1, operand2, operator } = this.state.question;
    if (operator === '+') {
      ans = operand1 + operand2;
    } else {
      ans = operand1 - operand2;
    }

    if (this.state.answer == ans) {
      if (this.mounted)
        this.setState({ isCorrect: true }, () => {
          clearInterval(this.state.intervalTimer);
          setTimeout(() => {
            this.setState(
              (st) => ({
                answer: '',
                isCorrect: false,
                numCorrect: st.numCorrect + 1,
                questionNum: st.questionNum + 1,
              }),
              this.generateQuestions()
            );
          }, 2000);
        });
    }
  };

  render() {
    const { operand1, operand2, operator } = this.state.question;
    const { isCorrect } = this.state;

    const renderProgress = <h2>Question {this.state.questionNum} of 2</h2>;

    if (isCorrect) {
      return (
        <div>
          <h1>
            Correct!{' '}
            <span role="img" aria-label="happy-face">
              😄
            </span>
          </h1>
        </div>
      );
    }

    if (!isCorrect && this.state.timeLeft <= 0) {
      setTimeout(() => {
        this.setState(
          (st) => ({
            answer: '',
            isCorrect: false,
            questionNum: st.questionNum + 1,
          }),
          this.generateQuestions()
        );
      }, 2000);
      return (
        <div>
          <h1>
            Wrong!{' '}
            <span role="img" aria-label="sad-face">
              😢
            </span>{' '}
          </h1>
        </div>
      );
    }

    return (
      <div>
        {renderProgress}
        <h1>
          What is {operand1} {operator} {operand2}?
        </h1>
        <h2>{this.state.timeLeft} seconds remaining.</h2>
        <input
          className="questions-input"
          type="number"
          ref={(input) => {
            this.answerInput = input;
          }}
          value={this.state.answer}
          onChange={(e) => this.handleChange(e)}
        ></input>
      </div>
    );
  }
}

Questions.defaultProps = {
  timeLeftInSeconds: 4,
};

export default Questions;
