import React, { Component } from 'react';
import InputPlayers from './InputPlayers';
import Dice from './Dice';
import DisplayScore from './DisplayScore';
class Game extends Component {
  state = {
    players: [],
    currentPlayer: 1,
    currentTurn: 1,
    currentFrame: 1,
    currentNumDice: 10,
    gameOver: false,
    loading: false,
  };

  //Create a new array of players, set the score of each to zero.
  addPlayers = (numOfPlayers) => {
    let tempArr = [];
    for (let i = 0; i < numOfPlayers; i++) {
      tempArr.push({ id: i + 1, scores: [] });
    }
    this.setState({ players: tempArr });
  };

  count(arr, element) {
    return arr.filter((e) => e === element).length;
  }

  //Add the score to the players object
  addScoreArr = (score, type) => {
    let { players, currentFrame, currentPlayer, currentTurn } = this.state;
    //Add the current score, frame, and turn to the player object
    let tempArr = JSON.parse(JSON.stringify(players));

    //Add the score to the scores array
    if (!tempArr[currentPlayer - 1].scores[currentFrame - 1]) {
      tempArr[currentPlayer - 1].scores.push({
        FrameScore: [{ score, currentTurn, type }],
        frame: currentFrame,
      });
    } else {
      tempArr[currentPlayer - 1].scores[currentFrame - 1].FrameScore.push({
        score,
        currentTurn,
        type,
      });
    }

    if (type === 'P1') {
      tempArr[currentPlayer - 1].scores[currentFrame - 1].isCompleted = true;
    }

    if (type === 'X' && currentFrame === 10) {
      tempArr[currentPlayer - 1].scores[currentFrame - 1].isCompleted = true;
      tempArr[currentPlayer - 1].scores[currentFrame - 1].FrameScore.push({
        score,
        currentTurn: 2,
        type,
      });
    }

    //Add pending scores
    if (tempArr[currentPlayer - 1].pending) {
      //If we are at the last frame, generate scores for the remaining pending frames. Make sure there are no pending left.

      let pendingFrame = tempArr[currentPlayer - 1].pending[0];

      if (currentFrame === 10) {
        tempArr[currentPlayer - 1].scores[currentFrame - 1].FrameScore.push({
          score: Math.floor(Math.random() * 10),
          currentTurn: 3,
          type: 'extra',
        });
        tempArr[currentPlayer - 1].pending[0].nextBalls--;
        //If pending is empty then delete the entire array from the property.
        if (pendingFrame.nextBalls === 0) {
          tempArr[currentPlayer - 1].pending.shift();

          //Remove the property if there is no pending
          if (!tempArr[currentPlayer - 1].pending.length)
            delete tempArr[currentPlayer - 1].pending;

          //Add the isCompleted attribute and set it to true.
          tempArr[currentPlayer - 1].scores[
            pendingFrame.frame - 1
          ].isCompleted = true;
        }
      }

      //Recheck for pending
      if (tempArr[currentPlayer - 1].pending) {
        //If the pendingFrame is the current frame do nothing
        //If the pendingFrame is not the current frame, add the current roll to the pending totalScore and remove one nextBalls from pending
        if (pendingFrame.frame - 1 !== currentFrame - 1) {
          if (
            !tempArr[currentPlayer - 1].scores[pendingFrame.frame - 1]
              .pendingScore
          ) {
            tempArr[currentPlayer - 1].scores[
              pendingFrame.frame - 1
            ].pendingScore = score;
          } else {
            tempArr[currentPlayer - 1].scores[
              pendingFrame.frame - 1
            ].pendingScore += score;
          }

          tempArr[currentPlayer - 1].pending[0].nextBalls--;
        }
        //If pending is empty then delete the entire array from the property.
        if (pendingFrame.nextBalls === 0) {
          tempArr[currentPlayer - 1].pending.shift();

          //Remove the property if there is no pending
          if (!tempArr[currentPlayer - 1].pending.length)
            delete tempArr[currentPlayer - 1].pending;

          //Add the isCompleted attribute and set it to true.
          tempArr[currentPlayer - 1].scores[
            pendingFrame.frame - 1
          ].isCompleted = true;
        }
      }
    }

    let totalScore = 0;
    for (let i = 0; i < tempArr[currentPlayer - 1].scores.length; i++) {
      let score = tempArr[currentPlayer - 1].scores[i];

      let finalScore = score.FrameScore.reduce(
        (acc, curr) => acc + curr.score,
        0
      );
      // Do we have a pending score to add?
      if (score.pendingScore) {
        //Add the pending score
        finalScore += score.pendingScore;
      }
      totalScore += finalScore;
      //Add the final score property.
      tempArr[currentPlayer - 1].scores[i].finalScore = totalScore;
    }

    return tempArr;
  };

  addRoll = (roll) => {
    const { currentFrame, currentPlayer, currentTurn, players } = this.state;
    let score = 0;
    let isLastFrame = false;

    //Frame 10 logic here.
    if (currentFrame === 10) {
      isLastFrame = true;
    }

    //First roll?
    if (currentTurn === 1) {
      //Are there any strikes?
      if (this.count(roll, 0)) {
        //Add the strike
        score = 10;

        //Keep track that we need to add the next two turns to the current frame
        let tempArr = JSON.parse(JSON.stringify(players));

        //Checks to see if we are on the last frame before adding pending balls.
        if (!isLastFrame) {
          //Checks to see if the pending property is an array before an object is pushed. If it is not, make it an array.
          if (!Array.isArray(tempArr[currentPlayer - 1].pending))
            tempArr[currentPlayer - 1].pending = [];

          //Push the frame and the number of balls.
          tempArr[currentPlayer - 1].pending.push({
            nextBalls: 2,
            frame: currentFrame,
          });
        }

        this.setState({ players: tempArr }, () => {
          //Add to the player object the score
          this.setState({ players: this.addScoreArr(score, 'X') }, () => {
            this.nextTurn();
          });
        });
        return;
      } else {
        //If not a strike:
        //Add the spares
        score += this.count(roll, 1);
        //Add the blanks
        score += this.count(roll, 2);

        //Add to score
        this.setState({ players: this.addScoreArr(score, 'P1') }, () => {
          //Did we get all die to be 10? If so, go to the next player's turn
          if (score === 10) {
            this.nextTurn();
            return;
          } else {
            //Update the num of dice left, go to the second turn.
            this.setState({ currentNumDice: 10 - score, currentTurn: 2 });
          }
        });
        return;
      }
    }

    //Second Roll?
    if (currentTurn === 2) {
      //Are there any spares?
      if (this.count(roll, 1)) {
        //Add the remaining pins
        score =
          10 -
          players[currentPlayer - 1].scores[
            players[currentPlayer - 1].scores.length - 1
          ].FrameScore[0].score;

        //Keep track that we need to add the next turns to the current frame
        let tempArr = JSON.parse(JSON.stringify(players));

        if (!isLastFrame) {
          //Checks to see if the pending property is an array before an object is pushed. If it is not, make it an array.
          if (!Array.isArray(tempArr[currentPlayer - 1].pending))
            tempArr[currentPlayer - 1].pending = [];

          //Push the frame and the number of turns we need to add later.
          tempArr[currentPlayer - 1].pending.push({
            nextBalls: 1,
            frame: currentFrame,
          });
        }

        this.setState({ players: tempArr }, () => {
          this.setState({ players: this.addScoreArr(score, '/') }, () => {
            this.nextTurn();
          });
        });
        return;
      } else {
        //If not a spare:
        //Add the strikes
        score += this.count(roll, 0);
        //Add the blanks
        score += this.count(roll, 2);

        let tempArr = JSON.parse(JSON.stringify(players));
        tempArr[currentPlayer - 1].scores[currentFrame - 1].isCompleted = true;
        this.setState({ players: tempArr }, () => {
          this.setState({ players: this.addScoreArr(score, 'P2') }, () => {
            this.nextTurn();
          });
        });
        return;
      }
    }
  };

  //Call to go to the next player's turn
  nextTurn = () => {
    //Set the current turn for the frame back to one, number of dice back to 10.
    this.setState({ currentTurn: 1, currentNumDice: 10 }, () => {
      //Have we itereated over all the current players?
      if (this.state.currentPlayer === this.state.players.length) {
        //Are we done with the game?
        if (this.state.currentFrame === 10) {
          this.setState({ gameOver: true });
        } else {
          //Change current turn back to the first player.
          this.setState((st) => ({
            currentPlayer: 1,
            currentFrame: st.currentFrame + 1,
          }));
        }
      } else {
        this.setState((st) => ({ currentPlayer: st.currentPlayer + 1 }));
      }
    });
  };

  renderGame = () => {
    let {
      currentPlayer,
      gameOver,
      currentFrame,
      currentTurn,
      currentNumDice,
      players,
    } = this.state;

    //Try to add another placeholder (currentTurnSpecs) which will display 2 chars if 1 will display st, if 2, nd, etc.
    //And another (stateSingular) for are / is and to switch between singular dice and plural die.
    if (!gameOver) {
      return (
        <div>
          <Dice
            currentFrame={currentFrame}
            numDice={currentNumDice}
            turn={currentTurn}
            addRoll={this.addRoll}
            currentPlayer={currentPlayer}
          />

          <DisplayScore
            currentFrame={currentFrame}
            currentPlayer={currentPlayer}
            players={players}
          />
        </div>
      );
    } else {
      let highestPlayer = players[0];
      for (let player of players) {
        if (player.scores[9].finalScore > highestPlayer.scores[9].finalScore)
          highestPlayer = player;
      }
      let isTied = false;
      let tiedPlayers = [];
      for (let player of players) {
        if (
          player.scores[9].finalScore === highestPlayer.scores[9].finalScore &&
          player.id !== highestPlayer.id
        )
          if (isTied === false) isTied = true;
        tiedPlayers.push(player.id);
      }
      return (
        <div>
          <DisplayScore players={players} />
          <h1>Game over!</h1>

          {isTied === false ? (
            <h2>
              Player {highestPlayer.id} won with a score of{' '}
              {highestPlayer.scores[9].finalScore}!
            </h2>
          ) : (
            <h2>Players with IDs {tiedPlayers} tied!</h2>
          )}
        </div>
      );
    }
  };

  render() {
    let { players } = this.state;
    let renderGame;
    //If there are no players, then render the start screen.
    if (!players.length) {
      renderGame = <InputPlayers addPlayers={this.addPlayers} />;
    } else {
      renderGame = this.renderGame();
    }

    return <div>{renderGame}</div>;
  }
}

Game.defaultProps = {
  frames: 10,
};

export default Game;
