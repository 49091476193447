import React, { Component } from 'react';

import './InputPlayers.css';

class InputPlayers extends Component {
  state = {
    numPlayers: 2,
  };

  handleChange = (e) => {
    this.setState({ numPlayers: e.target.value });
  };

  render() {
    return (
      <div className="InputPlayers">
        <h2>Instructions:</h2>
        <div className="Instructions">
          <p>Each player gets to roll the dice once per turn.</p>
          <p>
            If they roll a strike ('X') they get 10 points for this round and 10
            points added for the following two rounds.
          </p>
          <p>
            If you roll a spare ('/') the next roll gets 10 points added to its
            score.
          </p>
          <p>
            Each player gets to roll the dice 10 times, and the player with the
            highest score wins.
          </p>
        </div>
        <div className="Setup">
          <h2>Setup:</h2>
          <label>
            Select the number of players. Current: {this.state.numPlayers}
          </label>
          <div className="slidercontainer">
            <input
              className="slider"
              value={this.state.numPlayers}
              type="range"
              min="2"
              max="10"
              onChange={(e) => this.handleChange(e)}
            ></input>
          </div>

          <button
            className="button"
            onClick={() => this.props.addPlayers(this.state.numPlayers)}
          >
            Play!
          </button>
        </div>
      </div>
    );
  }
}
export default InputPlayers;
