import React from 'react';
import './Die.css';

const Die = ({ defaultDie, val }) => {
  return <div className="Die Shaking">{defaultDie[val]}</div>;
};

Die.defaultProps = {
  defaultDie: ['X', '/', '_', 'P'],
};

export default Die;
